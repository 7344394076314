.iconButton {
  border: 2px solid currentColor !important;
  border-radius: 4px !important;
}

.buttonText {
  justify-content: flex-start !important;
  font-weight: 400;
  font-size: 18px;
  width: 100%;
  height: 50px;
  /* color: white;   */
}
