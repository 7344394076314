.custom_container {
  width: 100%;
  border-radius: 8px;
}

.custom_tbl_pagination {
  display: flex;
  justify-content: flex-end;
}

.d_none {
  display: none !important;
}

.input_label_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ellipsis_two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-size: inherit;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}