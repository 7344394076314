.mui_card {
  height: 100%;
  width: 100%;
  position: relative;
  border: none;
}

.mui_card .hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.28);
}

.banner_img {
  height: 150px;
  width: 100%;
  object-fit: cover;
}
.logo_paper {
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 150px;
  height: 100px;
  margin: auto;
  border: none;
  border-radius: 15px;
  top: 100%;
  bottom: 0;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch {
  position: absolute;
  top: 100%;
  left: 0px;
}
.logo_img {
  width: 100px;
  cursor: pointer;
  object-fit: cover;
}
.title_text {
  font-size: 1.3rem;
  font-weight: 600;
}
.location_text {
  font-size: 0.8rem;
  font-weight: 400;
}
.summary_text {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: justify;
}
.edit_icon {
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 40px;
}
.delete_icon {
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 10px;
}
.logo_paper_dialog {
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 150px;
  height: 100px;
  margin: auto;
  border: none;
  border-radius: 15px;
  top: 85%;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner_img_dialog {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.callToActionBtnLbl {
  text-transform: none;
  background-color: #2f4858;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  color: white;
}
