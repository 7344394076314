.search_country_input_container:focus-visible {
  outline: none !important;
}

.search_country_input:focus-visible {
  outline: none !important;
}

.country_option {
  display: flex;
  align-items: center;
  flex-direction: row;            
  cursor: pointer;
}

.country_option:hover {
  background-color: rgba(120, 120, 120, 0.1);
}

.country_option:focus-visible {
  outline: none !important;
}