.sticky_buttons_container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  min-width: 300px;
  padding: 15px;
  display: flex;
  background-color: #fff !important;
  border: 1px solid #f2f2f2;
}
.MuiAutocomplete_popper {
  border-radius: 0;
  border-bottom: transparent;
  min-width: 300px !important;
  max-height: 250px !important;
  overflow-y: auto !important;
  margin-bottom: 45px !important;
}
.invoice_live_layout {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 210mm;
  height: "auto";
  border: 1px solid #ccc;
  padding: 20px;
}

/* styles.css or your preferred CSS file */
.custom-stack {
  width: 300px;
  margin-top: 10px;
  padding: 4px;
}

.custom-button-group {
  width: 100%;
  font-size: 20px;
}

/* Define more CSS classes for your buttons if needed */
.custom-button {
  font-size: 20px;
  width: 100%;
  height: 60px;
  text-align: "left";
  justify-content: flex-start;
}
.custom-Icon {
  font-size: 30px;
}
/* Define other CSS classes as needed */
.TableContainer {
  max-width: 100%;
}

.CustomTable {
  width: 100%;
  border-collapse: collapse;
}

.CustomTable th,
.CustomTable td {
  padding: 8px;
}
.table_header {
  font-size: var(--font-size);
  font-family: var(--font-family);
  font-weight: 600;
}
.CustomTable th {
  border-bottom: 2px solid black;
}

.CustomTable .table-header {
  font-size: var(--font-size);
  font-family: var(--font-family);
  font-weight: 600;
}

.container {
  position: relative;
  width: 210mm; /* A4 width */
  height: 297mm; /* A4 height */
  margin: auto;
}

.invoice {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 210mm;
  min-height: 297mm;
  border: 1px solid #ccc;
  padding: 20px;
}

.invoiceBgImage {
  box-sizing: border-box;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image */
  width: 100%;
  opacity: 0.3;
}

.container_live_layout {
  position: relative;
  width: 210mm; /* A4 width */
  height: "auto"; /* A4 height */
  margin: auto;
}

@media print {
  .invoice {
    margin: 0;
    border: none;
    padding: 0;
  }
}
