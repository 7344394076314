@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --scrollbar-width: calc(100vw - 100%);
  --sidebar_width: 260px;
}
main {
  width: 100%;
  min-height: 100vh;
}

@media (min-width: 600px) {
  main {
    width: calc(100vw - var(--sidebar_width) - var(--scrollbar-width));
    overflow-x: auto;
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: lightgrey #f5f5f5;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 20px;
  border: 3px solid #f5f5f5;
  margin: 20px;
}

.custom-link {
  text-decoration: none;
}

.custom-link p {
  border-bottom: 1px solid transparent !important;
}

.custom-link:hover p {
  border-bottom: 1px solid currentColor !important;
}

.link {
  text-decoration: none;
  color: inherit;
}

.caption-text {
  color: #a7a7a7;
}

.option_group_label {
  color: black;
  padding: 10px;
  cursor: default;
  pointer-events: none;
}

.option_group_option {
  color: #778899;
}

.delete_Icon {
  color: #e74c3c;
}

.edit_icon {
  color: #fd8e00;
}

button:disabled .edit_icon {
  color: #ffcd8d;
}

button:disabled .delete_Icon {
  color: #fb7e7e;
}

.stop_icon {
  color: #ff0000;
  font-size: 30px !important;
}

.play_icon {
  color: #2100ff;
  font-size: 30px !important;
}
.add-new-option {
  /* Add your styling here */
  font-weight: bold;
  background-color: #ffc107; /* Yellow background color, for example */
  color: #333; /* Text color */
  /* Add any other styling you want */
}

.characters {
  list-style: none;
  padding-left: 0;
}

.characters li {
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  border-radius: 0.2em;
  padding: 0.5em 0.8em 0.5em 0.5em;
  margin-bottom: 1em;
}

.characters p {
  max-width: none;
  font-weight: bold;
  margin: 0;
}

.characters-thumb {
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  background-color: #e8e8e8;
  padding: 0.5em;
  margin-right: 0.5em;
  max-height: 250px !important;
}

.characters-thumb img {
  display: block;
  width: 100%;
  height: auto;
}
.custom-button {
  border: 1px solid blue;
  width: 150px; /* Adjust width as needed */
}

.error_light_btn {
  color: #d32f2f !important;
  border: 1px solid #d32f2f !important;
  background: #fff2f2 !important;
}

.quill_editor {
  width: 100%;
  font-size: large !important;
}

.quill_editor .ql-container {
  height: 200px !important;
}

.quill_editor .ql-toolbar {
  background-color: #f9f9f9;
  border-radius: 6px 6px 0px 0px;
}

.quill_editor .ql-container {
  border-radius: 0px 0px 6px 6px;
}

.ql-editor {
  font-family: "Roboto,Helvetica,Arial,sans-serif";
  font-size: large;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.87);
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: +1;
}
.ProfessionalPage {
  padding: 1rem 2rem;
  background-color: grey;
}
.headerButtonLogo {
  border: none;
  background-color: transparent;
}
.headerButton {
  border: none;
  background-color: transparent;
  font-size: 18px;
  margin: 1rem 2rem;
}
.activePage {
  color: #d0af88;
}

.headerButton:hover {
  color: #d0af88;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.desktop {
  display: block !important;
}
.mobile {
  display: none !important;
}

.subCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem !important;
  height: 100%;
  border-radius: 15px !important;
  box-shadow: 0px 1px 3px 1px rgba(208, 175, 136, 1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important;
}
.selected::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: #d0af88;
  clip-path: polygon(100% 0%, 0% 0%, 100% 100%);
  border-radius: 0px 15px 0px 0px;
}
.selected::after {
  content: "\2713"; /* Unicode for check mark */
  position: absolute;
  top: 17px;
  right: 8px;
  font-size: 20px;
  color: white; /* Icon color */
  transform: translateY(-50%);
}
div[class^="section"] > div:not(.divider) {
  /* inside section except divider all div style */
  padding: 0px 2rem;
}

.divider {
  padding: 2rem 0px;
  font-weight: bolder;
  color: #d0af88;
}
.card {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left !important;
  padding: 2rem !important;
  border-radius: 15px !important;
  box-shadow: 0px 1px 3px 1px rgba(208, 175, 136, 1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important;
}
.subscription-card {
  display: inline-flex;
  width: -webkit-fill-available;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem !important;
  margin: 0.5rem !important;
  border-radius: 15px !important;
  box-shadow: 0px 1px 3px 1px rgba(208, 175, 136, 1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1) !important;
}
.imageCard {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.prizeBanner {
  margin: 1rem 3rem;
  padding: 1rem 3rem;
  margin-bottom: 2rem;
  font-size: xxx-large;
  /* background-color: #BADCED; */
  background-color: #d0af88;
  border-radius: 40px;
}
.borderBox {
  border: 1px solid #d0af88;
  border-radius: 5px;
  position: relative;
  text-align: center;
  padding-top: 10px;
}
.topText {
  position: absolute;
  top: -23px;
  left: 30%;
  color: #d0af88;
  font-size: 30px;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 0 10px;
}
.fullWidthMessage {
  width: -webkit-fill-available !important;
}
.activeHeader {
  font-weight: bold !important;
  color: #d0af88 !important;
}
.activeHeaderEffect:hover {
  color: #d0af88 !important;
}
.activeHeaderBold {
  font-weight: bolder !important;
  color: #d0af88 !important;
}
.headerStyle {
  display: flex;
  width: 100%;
  grid-column-start: 2;
  align-items: center;
  gap: 16px;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 2rem;
}
.buttonContainerStyle {
  display: flex;
  grid-column-start: 3;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}
.menuLinks {
  display: flex;
  grid-column-start: 2;
  gap: 16px;
}
.loginBtn {
  border: 1px solid #d0af88 !important;
  color: #d0af88 !important;
}
.buttonStyle {
  background-color: transparent;
  border: none;
  font-size: 1rem;
}

.pageStructure {
  padding: 2rem;
  background-color: rgb(243, 243, 243);
}

.poppins-title {
  color: var(--informer-dark);
  text-align: center;
  letter-spacing: -0.05px;
  text-transform: none;
  align-self: auto;
  margin-top: 0;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  line-height: 130%;
}
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.prizeContent {
  margin: 3rem;
  padding: 1rem 3rem;
}

.tryNow {
  background: linear-gradient(to bottom right, #f7bb97, #bdc3c7);
  padding: 2rem;
  text-align: center;
  border-radius: 20px;
  margin: 0px 2.5rem;
}
.titleUponDivider {
  text-align: center;
  font-weight: 300 !important;
  text-decoration: underline;
  font-style: italic;
}
.titleTexts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1.5rem;
}
.titleTexts button {
  margin-right: 1rem;
}
.findYourExpertBanner {
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("../public/Professional/findYourExpertBanner.png");
  background-size: cover;
  color: white;
}

.helpBanner {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-image: url("../public/Professional/HelpImage.png");
  background-size: cover;
}


.clientApp {
  scrollbar-width: none;
}
.clientApp * {
  scrollbar-width: none;
}

.stickyFaq {
  position: -webkit-sticky;
  position: sticky;
  overflow-y: auto; /* Changed to auto for better usability */
  scrollbar-width: thin; /* Firefox-specific */
  height: 500px;
  top: 7rem;
  width: 95%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  margin: 1rem;
  margin-left: 0px;
}

.stickyFaq {
  scrollbar-width: thin;
  scrollbar-color: rgba(208, 175, 136, 1) #f5f5f5;
}

/* Works on Chrome, Edge, and Safari */
.stickyFaq::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.stickyFaq::-webkit-scrollbar-track {
  background: #f5f5f5;
  margin-right: 20px;
}

.stickyFaq::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 20px;
  border: 3px solid #f5f5f5;
  margin: 20px;
}
.scroll {
  scrollbar-width: thin;
  scrollbar-color: rgba(208, 175, 136, 1) #f5f5f5;
}

/* Works on Chrome, Edge, and Safari */
.scroll::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.scroll::-webkit-scrollbar-track {
  background: #f5f5f5;
  margin-right: 20px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 20px;
  border: 3px solid #f5f5f5;
  margin: 20px;
}

.faqSearch {
  border: none;
  padding: 0px;
  padding-left: 1rem;
  margin: 0px;
  font-size: large;
  background: transparent;
  background-color: white !important;
  width: 90%;
}

.faqSearch:focus-visible {
  outline: none;
  background: transparent;
  background-color: white !important;
}
input:-internal-autofill-selected {
  outline: none;
  background: transparent;
  background-color: white !important;
}
.danger * {
  margin: 0px;
  padding: 5px;
}
.footerLink {
  text-decoration: none;
  gap: 10px;
  display: flex;
  justify-content: left;
  color: #ffffff;
  /* color: white; */
  margin-bottom: 10px;
}
.footer {
  background-color: black;
  padding: 0px 3rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.footerLink:hover {
  color: #d0af88;
}
.footerAdd {
  margin: 0px;
  color: #ffffff;
  text-align: left;
}
.footerMenuTitle {
  color: #ffffff;
  text-align: left;
}
.borderRadius {
  text-align: center;
  margin: 2rem 1rem !important;
  margin-bottom: 1rem !important;
  padding: 1rem 1.5rem !important;
  border-radius: 15px !important;
  border: 1px solid transparent;
  /* background: linear-gradient(black, black) padding-box padding-box, linear-gradient(to right, red, orange) border-box border-box; */
  background: linear-gradient(black, black) padding-box padding-box,
    linear-gradient(to right, #b69c29, #b58a31) border-box border-box;
}
.footerIconGroup {
  padding: 0px;
  justify-content: flex-end;
  gap: 5px;
  background-color: black;
}
.footerSocialIcon {
  padding: 10px;
  background-color: #cce6ff;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.footerSocialIcon img {
  vertical-align: middle;
  width: 30px;
}

.faqTitle {
  color: #d0af88 !important;
  font-weight: bold !important;
}
.faqTitle:hover {
  padding-left: calc(2rem - 5px) !important;
  border-left: 5px solid #d0af88;
}
.faqSearchNoData {
  color: #130e00;
  background-color: #cfcfcf;
  border: none;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 1rem;
}
/* .faqTitleActive {
  border-left: 5px solid #d0af88;
} */

.buttonDesign {
  display: inline-block !important;
  /* background-color: #f9f9f9 !important; */
  /* background-color: #d0af88 !important; */
  background: linear-gradient(to bottom right, #f7bb97, #a7b6c2);
  color: black !important;
  width: max-content !important;
  box-shadow: none !important;
  margin: 0px 0.5rem !important;
}
.buttonBox {
  justify-content: space-evenly;
  text-align: center;
  /* background-color: #ffffff; */
  border-radius: 5px;
  white-space: nowrap;
  overflow-x: scroll;
}

.buttonBox {
  scrollbar-width: thin;
  scrollbar-color: transparent;
}

.buttonBox::-webkit-scrollbar {
  width: 12px;
  margin: 1rem;
}

.buttonBox::-webkit-scrollbar-track {
  background: transparent;
}

.buttonBox::-webkit-scrollbar-thumb {
  background-color: #d0af88;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  border: 3px solid transparent;
}

.buttonBox::-webkit-scrollbar-button {
  height: 0px !important;
  width: 0px !important;
}

.buttonBox {
  scrollbar-color: #d0af88 transparent;
  padding: 1rem !important;
}

/* Privacy page */
.cardPage {
  margin: 1rem 8rem;
  margin-bottom: 8rem;
  padding: 1rem 2rem;
  background-color: white;
  border-radius: 15px;
}

.pageStart {
  padding: 1rem 3rem;
  background-color: #fafbfc;
}

.buttonEffect {
  border: 0px solid #d0af88;
  letter-spacing: 0.6px;
  border-radius: 14px;
  color: white;
  justify-content: center;
  align-self: flex-start;
  margin-top: 0;
  background-color: #d0af88;
  padding: 14px 30px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.buttonEffect:hover {
  background-color: rgba(208, 175, 136, 0.8);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
  /* mobile design */
  .footerIconGroup {
    margin-bottom: 2rem;
    justify-content: center;
  }
  .mb2mobile {
    margin-bottom: 2rem;
  }
  .stickyFaq {
    height: auto;
  }
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
  .prizeBanner {
    margin: 1rem;
    padding: 1rem;
  }
  .prizeBanner p {
    font-size: 16px;
  }

  .prizeBanner h1 {
    font-size: 26px;
    font-weight: bold;
  }

  .prizeBanner h2 {
    font-size: 24px;
    font-weight: bold;
  }

  .prizeBanner h5 {
    font-size: 16px;
  }
  .prizeBanner button {
    margin: auto;
    display: block;
    font-size: 12px !important;
  }
  .prizeBanner div {
    margin: 10px 0px !important;
  }
  .divider {
    font-size: 15px !important;
  }
  div[class^="section"] h6 {
    /* inside section h6 style */
    font-size: 15px;
  }
  .prizeContent {
    margin: 1rem;
    padding: 1rem;
  }
  .titleTexts {
    flex-direction: column;
    padding: 1rem;
  }
  .titleTexts button {
    margin: 0px;
    margin-bottom: 1rem;
  }
}
@media (max-width: 878px) {
  /* tab design */
}
